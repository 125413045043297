@font-face {
    font-family: 'Untitled-Sans-Light';
    src: url('../assets/fonts/Untitled_Sans_Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Untitled-Sans-Regular';
    src: url('../assets/fonts/Untitled_Sans_Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Untitled-Sans-Medium';
    src: url('../assets/fonts/Untitled_Sans_Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}
