@use '@angular/material' as mat;
@import '../theme.scss';
@import 'variables';

.button,
.SimpleButton {
    padding: 0.625rem 2rem;
    border-radius: 4px;
    border: none;
    box-sizing: border-box;
    text-align: center;
    cursor: pointer;
    font-weight: 500;
    transition: 0.3s;
    white-space: nowrap;
}

.button,
.button:active,
.button:hover,
.button:focus {
    text-decoration: none;
}

.button_small {
    font-size: 1rem;
    line-height: 1.25rem;
}

.button_medium,
.SimpleButton {
    font-size: 1.25rem;
    line-height: 2rem;
}

.button_big {
    padding: 1rem 2rem;
    font-size: 1.4375rem;
    line-height: 1.8125rem;
}

.button_primary,
.mat-flat-button.mat-primary {
    background-color: mat.get-color-from-palette($app-primary, 500);
    border: 2px solid mat.get-color-from-palette($app-primary, 500);
    color: white;

    &:hover:not([disabled]),
    &:hover:enabled {
        background-color: darken(mat.get-color-from-palette($app-primary, 500), 35%);
        border-color: darken(mat.get-color-from-palette($app-primary, 500), 35%);
    }
}

.mat-flat-button {
    &:hover:not([disabled]),
    &:hover:enabled {
        background-color: #e1e1e1;
    }
}

.button_secondary {
    background-color: transparent;
    color: $text-color;
    border: 2px solid $text-color !important;

    &:hover:not([disabled]),
    &:hover:enabled,
    &:focus:not([disabled]),
    &:focus:enabled,{
        background-color: rgba(0,0,0,0.04);
    }
}

.button_primary, .button_secondary {
    &:disabled {
        opacity: 0.3;
    }
}

.button_new-item {
    font-size: 1.25rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.SimpleButton {
    @extend .btn-auth;

    background-color: transparent;
    border-width: 0;

    ddp-sign-in-out & {
        border: 2px solid $text-color;
        background-color: transparent;
    }

    ddp-language-selector & {
        display: flex;
        align-items: center;
    }

    .footer & {
        color: white;
        border-color: white;

        &:hover:not([disabled]),
        &:hover:enabled {
            background-color: $footer-divider-color;
        }
    }
}

.btn-auth {
    padding: 0.7rem 1.75rem !important;
    line-height: 20px !important;

    &.dashboard-button {
        padding: 0.7rem !important;
    }
}

.small-letter-spacing {
    letter-spacing: -0.016rem;
}

.button-link {
    border: none;
    background: none;
    font-size: inherit;
    font-family: inherit;
    color: $primary-color;

    &:hover, &:focus {
        text-decoration: underline;
    }
}

@media screen and (max-width: $mobile-breakpoint) {
    .button,
    .SimpleButton {
        padding-left: 1rem;
        padding-right: 1rem;
        font-size: 1.2rem;
        white-space: normal;
    }
}
